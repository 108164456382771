import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
// import Hero from '../components/Hero'
import HeroVideo from '../components/HeroVideo'
import Main from '../components/Main'
import styled from 'react-emotion'
import Button from '../components/Button'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import Panel from '../components/panel'

import videoAbout from '../assets/about-compressed.mp4'
import posterFrame from '../assets/about-poster.jpg'

export default () => (
  <Layout>
    <Helmet>
      <title>Cruisio: About Us</title>
    </Helmet>
    <HeroVideo
      cover={videoAbout}
      poster={posterFrame}
      headText="About Cruisio"
      subheadText="We believe in reducing atmospheric  carbon through dramatic engine efficiency gains"></HeroVideo>
    <Main>
      <Panel>
        <h1>We're Cruisio</h1>
        <p>We believe it’s possible and easy to improve fuel efficiency across the world to create a low carbon future. Born on the autobahn through the imitation of nature’s pulsed drive systems, the patented pulsed cruise helps company deliver on a triple bottom line.  The team at Cruisio is helping people save money without impacting vehicle performance by dramatically lowering fuel consumption and significantly reducing the world’s carbon footprint.</p>

      </Panel>
      <Panel className="alt">
        <h1>Who We Are</h1>
        <p>Cruisio’s team is made up of the hardworking minds from that have bridged theoretical to practical.   We are veterans in tech, service, IP, and automotive.</p>

        <p><strong>Dr. Jeffrey Yu, MD</strong>, our CEO and Chairman has spent his career helping people through his research and inventions. He founded Cruisio to change the trajectory of fuel consumption usage globally. Jeffrey continues to be active as a physician and entrepreneur with Board certifications in Radiology and Nuclear Medicine. He has taken his experience in executive level positions in health care organizations and startups and applied it in the development and commercialization of our patented technology.</p>

        <p><strong>Jay Dilley, our Director of Business Development</strong>, is passionate about tech innovations fundamentally improving peoples lives and how diversity in all its forms create better outcomes. An experienced excutive in the transportation industry, he has held President and VP roles while leading companies through transitions where he was recognized for championing women in leadership and as a top 40 under 40 receipient.  He is holds a MSc. Mgt and is a CFA Charterholder.</p>

        <p><strong>Dr. Gregory Davis</strong>, our VP of Engineering is a Professor at Kettering University, and an expert in automotive engineering and energy systems.  He is the Director of Advanced Engine Research Laboratory at Kettering, he received his Ph.D. from University of Michigan and is the author of over two dozen technical articles.  At Cruis, he is responsible for product engineering, technology development, and validation testing.</p>

        <p><strong>Thomas Y. Yee, Esq.</strong> is an Intellectual Property Attorney and is responsible for Cruis’ intellectual property development and maintenance.  He is a Silicon Valley veteran with seed, venture and incubators experience in addition to earning his JD from Georgetown University Law Center and his BS in Biomedical Engineering from Columbia University.</p>

      </Panel>
    </Main>
  </Layout>
)
